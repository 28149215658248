import React, { Suspense, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { t } from '../../utils/i18next';
import AppContainer from './AppContainer';
import UncaughtErrorsHandler from './general/UncaughtErrorsHandler';
import LazyLoadWithRetry from './general/LazyLoadWithRetry';
import { storeContext } from '../context';
import SharedComponentsContextProvider from '../context/SharedComponentsContextProvider';
import AppLoaderHandler from './general/Loaders/AppLoaderHandler';
import { loadScriptsList } from '../../utils/LoadExternalScript';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NotificationPanel from './routes/login/NotificationPanel/NotificationPanel';

const ThemeProvider = LazyLoadWithRetry(() => import('../theme/ThemeProvider'));
const Header = LazyLoadWithRetry(() => import('./Header/Header'));
const PagesRouter = LazyLoadWithRetry(() => import('./routes/PagesRouter'));
const Footer = LazyLoadWithRetry(() => import('./Footer/Footer'));

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const App = observer(() => {
  //temporary solution for the shared_components located in components/general
  //once shared_components is consumed as external library, remove useContext and SharedComponentsContextProvider
  const {
    translations: { currentLanguage },
    session
  } = useContext(storeContext);

  const contentId = 'main-content';

  return (
    <div className="App">
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: currentLanguage }} />
        <UncaughtErrorsHandler>
          <Suspense fallback={<AppLoaderHandler />}>
            <ThemeProvider>
              <SharedComponentsContextProvider language={currentLanguage}>
                <AppContainer>
                  <Header contentId={contentId} />
                  {session.boUserEmail && (
                    <NotificationPanel
                      isFixed={true}
                      header={t('login.sso_through', {
                        identifier: session.boUserEmail
                      })}
                    />
                  )}
                  <PagesRouter id={contentId} />
                  <Footer />
                </AppContainer>
              </SharedComponentsContextProvider>
            </ThemeProvider>
          </Suspense>
        </UncaughtErrorsHandler>
      </HelmetProvider>
    </div>
  );
});

export default App;
